const levels = [
  "level1",
  "level2",
  "level3",
  "level4",
  "level6",
  "level7",
  "level9",
  "level8",
];
export default levels;
